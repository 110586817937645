import * as React from 'react';
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {JiraService} from "../services/JiraService";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import LegendAndFilters from "./LegendAndFilters";
import {CalendarEvent} from "../interfaces/CalendarEvent";
import {toast} from "react-toastify";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

interface PageProps {

}

interface PageState {
    startDate: string
    endDate: string
    calenderLoading: boolean
    posts: any
    modalIsOpen: boolean
    changeTable: any
    applications: any
    filters: any
    monthStart: any
    monthEnd: any
}

export default class CalendarContainer extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);
        this.state = {
            startDate: '',
            endDate: '',
            calenderLoading: false,
            posts: [],
            changeTable: '',
            modalIsOpen: false,
            applications:[],
            filters: {},
            monthStart:'',
            monthEnd: ''
        };
    }

    //fetches posts for calendar by single month if dates available to do so
    getPostsMonth = async (e?:any) => {
        this.setState({calenderLoading: true});
        let jiraService = new JiraService();
        let filters = this.state.filters;
        let start = '';
        let end = '';

        if (e) {
            // function called from changing selected month in calendar
            start = getFormattedDate(new Date(e["start"]));
            end = getFormattedDate(new Date(e["end"]));
            this.setState({monthStart: start, monthEnd: end});
        } else {
            if (this.state.monthStart === '' && this.state.monthEnd === '') {
                // function called from app didMount
                start = getFormattedDate(moment().startOf('month').startOf('week').toDate());
                end = getFormattedDate(moment().endOf('month').endOf('week').toDate());
            } else {
                // function called from changing filters
                start = this.state.monthStart;
                end = this.state.monthEnd;
            }
        }

        let _events = await jiraService.fetchPostsByMonth(start.toString(), end.toString());
        if (_events.itemlist !== undefined) {
            await this.setState({
                posts: _events.itemlist.filter(filterEvent)
            });
        }
        else{
            toast.error('Error attempting to retrieve calendar posts.');
        }

        //formatDates
        function getFormattedDate(date: Date) {
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return year + '/' + month + '/' + day;
        }

       //filtering function
        function filterEvent(event: CalendarEvent) {
            if (Object.keys(filters).length !== 0) {
                if ((Date.parse(event.start) >= filters.startDate || filters.startDate === '' || filters.startDate === null) &&
                    (Date.parse(event.start) <= filters.endDate || filters.endDate === '' || filters.endDate === null) &&
                    (event.environment === filters.env || filters.env === '') &&
                    (event.interconnection.includes(filters.interconnection) || filters.interconnection === '') &&
                    (event.memberImpacting === filters.memberImpacting || filters.memberImpacting === '') &&
                    (event.memberFacing === filters.memberFacing || filters.memberFacing === '') &&
                    ( (filters.status && event.status === 'Published') || filters.status === false)) {

                        if (filters.apps === undefined || filters.apps.length === 0) {
                            return true; // no applications in filter
                        } else {
                            // check if any app in filter dropdown is in event applications
                            for (const app of filters.apps) {
                                if (event.applications.includes(app)) {
                                    return true;
                                }
                            }
                            // if we made it through the loop without returning, then it must not be in there
                            return false;
                        }

                }
            } else {
                return true;
            }
        }
    };

    eventSelect=(event: any)=>{
        this.setState({changeTable: event.popup});
        this.openModal();
    };

    openModal=()=> {
        this.setState({modalIsOpen: true});
    };

    closeModal=()=> {
        this.setState({modalIsOpen: false});
    };

    eventStyleGetter=(event: any) =>{
        let newStyle = {
            backgroundColor: "var(--spp-dark-blue)",
            textDecoration: "",
        };
        if (event.environment !== "PROD" && event.status === 'Published'){
            newStyle.backgroundColor = "var(--spp-dark-green)"
        }
        if(event.environment !== "PROD" && event.status === 'Canceled') {
            newStyle.backgroundColor = "var(--light-green)"
            newStyle.textDecoration = 'line-through'
        }
        if(event.environment === "PROD" && event.status === 'Canceled') {
            newStyle.backgroundColor = "var(--light-blue)"
            newStyle.textDecoration = 'line-through'
        }
        return {
            className: "",
            style: newStyle
        }
    };

    async componentDidMount (){
       await this.getPostsMonth();
    }


    updateFilters = async (filters:any) =>{
        await this.setState({filters:filters});
        this.getPostsMonth();
    }

    public render() {
        const localizer = momentLocalizer(moment);
        return (
            <Container fluid={true}>
                <ToastContainer/>

                <Container id="main_content_container">
                    <div className="main_content_sidebar">
                        <LegendAndFilters updateFilters={this.updateFilters} />
                    </div>
                    {/*<div style={{height: "50px"}}/>*/}
                    <div style={{display: "flex", overflowX: "auto", flex: "1 1 830px", width:"100%"}}>
                        <div className="main_content_calendar">
                            <Calendar localizer={localizer}
                                      defaultDate={moment().toDate()}
                                      defaultView="month"
                                      views={{month: true}}
                                      popup={true}
                                      events={this.state.posts}
                                      onSelectEvent={this.eventSelect}
                                      eventPropGetter={this.eventStyleGetter}
                                      onRangeChange={this.getPostsMonth}
                            />
                        </div>
                    </div>
                </Container>


                <div className={'changeDetailModal'}>
                    <Modal
                        open={this.state.modalIsOpen}
                        onClose={this.closeModal}
                        id={'changeDetailModal'}
                    >
                        <Modal.Content>
                            <div id={'changeDetailTable'}>{this.state.changeTable}</div>
                            <br />
                            <Button floated='right'
                                    onClick={this.closeModal}
                                    id={'changeDetailModalButton'}
                                    style={{margin:'20px'}}>
                                Close
                            </Button>
                        </Modal.Content>
                    </Modal>
                </div>
            </Container>
        )
    }
}

