import {CalendarEvent, CalendarEventList} from "../interfaces/CalendarEvent";
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import * as React from "react";
import {Icon, Label, LabelGroup, Menu, Popup} from "semantic-ui-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import HelpTips from "../help-tips.json";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

export class JiraService {

    fetchPostsByMonth = async (date1?: String, date2?: String) => {

        let _events: CalendarEventList = {
            itemlist:[]
        };
        let url="/postsByMonth?date1='" + date1 + "'&date2='" + date2 + "'";

        await axios.get(url)
            .then(resp => {
                    // @ts-ignore
                    resp.data.forEach(function(issue: any){
                        let event = {} as CalendarEvent;
                        event.start = new Date(issue["Begin Date"]).toString();
                        event.end = new Date(issue["End Date"]).toString();
                        event.environment=issue.CWG_Environment;
                        event.expedited = issue.CWG_Expedited;
                        event.status=issue.status;
                        event.title=issue.summary + " [" + issue["CWG_ChangeType"] + "]" + (event.expedited === 'Yes' ? " - EXPEDITED" : "");
                        event.applications = issue["component"];
                        event.memberFacing = issue["CWG_MemberFacing"];
                        event.memberImpacting = issue["CWG_MemberImpacting"];
                        event.interconnection = issue["SPP Interconnect Impact"].toString().replace("-"," & ");

                        let systems_labels: any[] = [];
                        issue["component"].forEach((comp: String) => systems_labels.push(<Label basic color={((issue.status === 'Canceled') ? 'red' : 'black')} key={comp.toString()}>{comp}</Label>));

                        // @ts-ignore
                        let changeTypeHelp: string = HelpTips.EVENT_MODAL.CLASSIFICATION_DESCRIPTIONS[issue.CWG_ChangeType.toString()];

                        // TODO: fix typscript problem in here and then remove ts-ignore if possible (created by peter)
                        // @ts-ignore
                        event.popup =
                            <div>
                                <div id="event_popup_top_row">
                                    <Menu compact>
                                        <Menu.Item style={{backgroundColor:'lightgrey'}}>Reference ID</Menu.Item>
                                        <Menu.Item as='a'>
                                            <i>{(issue["CWG_RequestNumber"] || issue.id)}</i>
                                            <CopyToClipboard text={(issue["CWG_RequestNumber"])}><Button id="copy" onClick={() => toast.info("ID Copied")}>Copy ID</Button></CopyToClipboard>
                                        </Menu.Item>
                                    </Menu>

                                    <Label id="La1" size='big'
                                           className={((issue.status === 'Published') ? 'dark' : 'light') + '-' + ((issue.CWG_Environment === 'PROD') ? 'blue' : 'green') }
                                           style={{color:'white', fontWeight:'normal', float:'right'}}>
                                        {(issue.CWG_Environment === 'PROD') ? 'Production Environment' : 'Member Testing Environment'}
                                    </Label>
                                </div>

                                <div style={{margin:'50px 50px 10px'}}>
                                    <h1>
                                        {issue.summary} &ensp;
                                        {(event.expedited === 'Yes') ?
                                            <Label size='huge' color='grey' style={{fontWeight:'normal'}}>EXPEDITED</Label>
                                            :
                                            <span />
                                        }
                                        {(issue.status === 'Canceled') ?
                                            <Label size='huge' color='red' style={{fontWeight:'normal'}}>CANCELED</Label>
                                            :
                                            <span />
                                        }
                                    </h1>
                                    <div><i>{issue.description}</i></div>
                                    <br />
                                    <br />

                                    <p><b>Interconnections Impacted</b></p>
                                    <LabelGroup>
                                        {event.interconnection.includes('East') ? <Label basic color={((issue.status === 'Canceled') ? 'red' : 'orange')}>EAST</Label> : <div />}
                                        {event.interconnection.includes('West') ? <Label basic color={((issue.status === 'Canceled') ? 'red' : 'purple')}>WEST</Label> : <div />}
                                    </LabelGroup>

                                    <br />

                                    {/*<p><b>Impacted Systems</b> <Popup content={HelpTips.EVENT_MODAL.IMPACTED_SYSTEMS} trigger={<Icon link name="info circle" />} /></p>*/}
                                    <p><b>Systems Impacted</b></p>
                                    <LabelGroup>{systems_labels}</LabelGroup>



                                    <br />
                                    <br />
                                    <p><b>Details</b></p>

                                    <Table definition collapsing celled={true} color={((issue.status === 'Canceled') ? 'red' : undefined)}>
                                        <Table.Body className={getClassName(issue)}>
                                           <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Classification</Table.Cell>
                                               <Table.Cell>{issue.CWG_ChangeType} <Popup content={changeTypeHelp} trigger={<Icon link name="info circle" />} /> </Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Member Facing? <Popup content={HelpTips.EVENT_MODAL.MEMBER_FACING} trigger={<Icon link name="info circle" />} /></Table.Cell>
                                                <Table.Cell style={{fontWeight:((issue.CWG_MemberFacing === 'Yes' ? 'bold' : 'normal'))}}>{issue.CWG_MemberFacing}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Member Impacting? <Popup content={HelpTips.EVENT_MODAL.MEMBER_IMPACTING} trigger={<Icon link name="info circle" />} /></Table.Cell>
                                                <Table.Cell style={{fontWeight:((issue.CWG_MemberImpacting === 'Yes' ? 'bold' : 'normal'))}}>{issue.CWG_MemberImpacting}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Anticipated Impact</Table.Cell>
                                                <Table.Cell>{issue.CWG_AnticipatedImpact}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Possible Risks</Table.Cell>
                                                <Table.Cell>{issue.CWG_PossibleRisks}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Testing Requirements</Table.Cell>
                                                <Table.Cell>{issue.CWG_TestingRequirements}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>

                                    <br />
                                    <br />
                                    <p><b>Timing</b></p>
                                    <Table definition collapsing celled={true} color={((issue.status === 'Canceled') ? 'red' : undefined)} style={{marginTop:'0px'}}>
                                        <Table.Body className={getClassName(issue)}>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Start Time</Table.Cell>
                                                <Table.Cell>
                                                    {new Date(issue["Begin Date"]).toString()}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Expected End Time</Table.Cell>
                                                <Table.Cell>
                                                    {new Date(issue["End Date"]).toString()}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row negative={(issue.status === 'Canceled')}>
                                                <Table.Cell>Expected Outage Duration</Table.Cell>
                                                <Table.Cell>
                                                    {issue.CWG_ExpectedOutage}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </div>
                                <br />
                            </div>


                        function getClassName(issue: any) {
                            if(issue.status === 'Pending Review')
                            {
                                return 'pendingChangeTable'
                            }
                            else if((issue.status === 'Published') && issue.CWG_Environment === 'PROD')
                            {
                                return 'productionChangeTable'
                            }
                            else if ((issue.status === 'Published') && issue.CWG_Environment === 'MTE')
                            {
                                return 'mteChangeTable'
                            }
                            else if (issue.status === 'Cancelled') {
                                return 'cancelledRejectedTable'
                            }
                            else
                            {
                                return ''
                            }
                        }
                        _events.itemlist.push(event)
                    })
                }
                )
            .catch(resp => {
                // @ts-ignore
                delete _events.itemlist;
            });
        return _events;
    };

    fetchComponents = async () =>{
        let componentData = {}
        await axios.get('/systems')
            .then(resp => {
                componentData = resp.data;
                }
            );
        return componentData;
    }
}