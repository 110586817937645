import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Dropdown, Icon, Button, Checkbox, Popup, Segment, Modal, Label} from "semantic-ui-react";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {JiraService} from "../services/JiraService";
import HelpTips from "../help-tips.json"


interface PageProps {
    updateFilters?:any
}

interface filters {
    startDate: string
    endDate: string
    apps: string[]
    env: string
    interconnection: string
    memberFacing: string
    memberImpacting: string
    status: boolean
}

interface PageState {
    startDate: string
    endDate: string
    applications: any
    selectedEnv: string
    selectedInterconnection: string
    selectedApps: string[]
    selectedMemberFacing: string
    selectedMemberImpacting: string
    status: boolean
    legendOpen: boolean
    filtersOpen: boolean
    filterCount: number
}


export default class LegendAndFilters extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);
        this.state = {
            startDate: '',
            endDate: '',
            applications: [],
            selectedEnv: '',
            selectedInterconnection: '',
            selectedApps: [],
            selectedMemberFacing: '',
            selectedMemberImpacting: '',
            status: false,
            legendOpen: false,
            filtersOpen: false,
            filterCount: 0
        };
    }

   async componentDidMount() {
       let jiraService = new JiraService();
       let components = await jiraService.fetchComponents();
       let applicationList: any[] = [];

       // TODO: tslint doesn't like this. sounds like tslink is depricated and we should go to eslint
       // @ts-ignore
       for (const [key, value]: [string, string] of Object.entries(components)) {
           let name = key.toString(); //.replace('[','').replace(']','');
           let desc: string;
           // @ts-ignore
           desc = value === null ? "No description provided" : value.toString();
           let o = {
               key: name,
               text: name,
               value: name,
               desc: desc
           };
           applicationList.push(o)
       }

       await this.setState({applications: applicationList});

    };

    // updateStartDate  = async (event:any, data:any) => {
    //     await this.setState({startDate: data.value})
    //     this.updateFilters();
    // };
    // updateEndDate = async (event:any, data:any) => {
    //     await this.setState({endDate: data.value})
    //     this.updateFilters();
    // };

    envChange = async (event:any, data:any) => {
        await this.setState({selectedEnv: data.value})
        await this.updateFilters();
    };

    interconnectionChange = async (event:any, data:any) => {
        await this.setState({selectedInterconnection: data.value})
        await this.updateFilters();
    };

    applicationChange = async (event:any, data:any) => {
        await this.setState({selectedApps: data.value})
        await this.updateFilters();
    };
    memberFacingChange =  async (event:any, data:any) => {
        await this.setState({selectedMemberFacing: data.value})
        await this.updateFilters();
    };
    memberImpactingChange = async (event:any, data:any) => {
        await this.setState({selectedMemberImpacting: data.value})
        await this.updateFilters();
    };
    memberStatusChange = async (event:any, data:any) => {
        await this.setState({status: data.checked});
        await this.updateFilters();
    };

    updateFilters = async () => {
        let filters = {} as filters;
        filters.startDate = this.state.startDate;
        filters.endDate = this.state.endDate;
        filters.env = this.state.selectedEnv;
        filters.interconnection = this.state.selectedInterconnection;
        filters.apps = this.state.selectedApps;
        filters.memberFacing = this.state.selectedMemberFacing;
        filters.memberImpacting = this.state.selectedMemberImpacting;
        filters.status = this.state.status;
        this.props.updateFilters(filters);

        let filterCount = 0;
        if (this.state.selectedEnv !== "") { filterCount++; }
        if (this.state.selectedInterconnection !== "") { filterCount++; }
        if (this.state.selectedApps.length > 0) { filterCount++; }
        if (this.state.selectedMemberFacing !== "") { filterCount++; }
        if (this.state.selectedMemberImpacting !== "") { filterCount++; }
        if (this.state.status) { filterCount++; }
        await this.setState({filterCount: filterCount})
    };

    handleClear = async (event:any) => {
        await this.setState({selectedEnv: '',
            selectedInterconnection: '',
            selectedApps: [],
            selectedMemberFacing: '',
            selectedMemberImpacting: '',
            status: false,
            filterCount: 0});
        await this.updateFilters();
    }

                /*startDate: '',
                endDate: '',
                applications: [],
                selectedEnv: '',
                selectedApps: [],
                selectedMemberFacing: '',
                selectedMemberImpacting: ''
                */
    public render() {
        const envOptions = [
            {
                key: 'PROD',
                text: 'Production',
                value: 'PROD'
            },
            {
                key: 'MTE',
                text: 'MTE',
                value: 'MTE'
            }
        ];

        const InterconnectionOptions = [
            {
                key: 'East',
                text: 'East',
                value: 'East'
            },
            {
                key: 'West',
                text: 'West',
                value: 'West'
            }
        ];

        return (
            //boarder style code if you want to later divied legend and filters into two separate divs and grids
            //*<div style={{border: 'solid 1px', borderRadius: '10px', padding: '10px'}}>*/}
            <div>



                {/*<Button*/}
                {/*    content={<span><Icon name="filter" /> {this.state.legendOpen ? 'Close Filters' : 'Open Filters'}</span>}*/}
                {/*    negative={this.state.filtersOpen}*/}
                {/*    positive={!this.state.filtersOpen}*/}
                {/*    onClick={this.handleFiltersButtonClick}*/}
                {/*/>*/}

                {/* LEGEND */}
                <Modal
                    basic
                    size="mini"
                    closeIcon
                    onClose={() => this.setState({legendOpen: false})}
                    onOpen={() => this.setState({legendOpen: true})}
                    open={this.state.legendOpen}
                    trigger={
                        <Button
                        content="Legend"
                        icon="list layout"
                        labelPosition="left"
                        style={{margin:"0px 20px", border:"1px solid lightgrey"}}
                        />
                    }>
                    <div className="module">
                        <div className="module-title">LEGEND</div>
                        <div className="module-content">

                            <div className="module-header-1">Production Changes:</div>
                            <div className="module-wrap-container justify-contents">
                                <div className="change-event-legend dark-blue">Planned</div>
                                <div className="change-event-legend light-blue">Canceled</div>
                            </div>

                            <div className="module-header-1">Member Testing Environment (MTE) Changes:</div>
                            <div className="module-wrap-container justify-contents">
                                <div className="change-event-legend dark-green">Planned</div>
                                <div className="change-event-legend light-green">Canceled</div>
                            </div>

                        </div>
                    </div>
                </Modal>

                {/*<br />*/}


                 {/*FILTERS */}
                <Modal
                    basic
                    size="mini"
                    closeIcon
                    onClose={() => this.setState({filtersOpen: false})}
                    onOpen={() => this.setState({filtersOpen: true})}
                    open={this.state.filtersOpen}
                    trigger={
                        <Button
                            content={<span>Filters {this.state.filterCount > 0 ? <Label color='red' circular floating>{this.state.filterCount}</Label> : <div/>}</span>}
                            icon="filter"
                            labelPosition="left"
                            style={{border:"1px solid lightgrey"}}
                        >
                        </Button>
                    }>
                    <div className="module">
                        <div className="module-title">FILTERS</div>
                        <div className="module-content" style={{ display: "flex" }}>
                            <div style={{margin:"10px auto", position:"relative", display:"inline-block", minWidth:"200px"}}>

                                <p className="module-header-1">Environment <Popup content={HelpTips.MAIN_PAGE.ENVIRONMENT} trigger={<Icon link name="info circle" />} /></p>
                                <Dropdown selection fluid
                                          options={envOptions}
                                          onChange={this.envChange}
                                          value={this.state.selectedEnv}
                                          clearable={true} />

                                <p className="module-header-1">Interconnection <Popup content={HelpTips.MAIN_PAGE.INTERCONNECTION} trigger={<Icon link name="info circle" />} /></p>
                                <Dropdown selection fluid
                                          options={InterconnectionOptions}
                                          onChange={this.interconnectionChange}
                                          value={this.state.selectedInterconnection}
                                          clearable={true} />

                                <p className="module-header-1">Impacted System(s) <Popup content={HelpTips.MAIN_PAGE.IMPACTED_SYSTEM} trigger={<Icon link name="info circle" />} /></p>
                                    <Dropdown selection search multiple
                                              id="D1"
                                              options={this.state.applications}
                                              onChange={this.applicationChange}
                                              value={this.state.selectedApps}
                                              clearable={true}/>

                                <p className="module-header-1">Member Facing <Popup content={HelpTips.MAIN_PAGE.MEMBER_FACING} trigger={<Icon link name="info circle" />} /></p>
                                <Dropdown selection fluid
                                          options={[{key:'Yes', value:'Yes', text:'Yes'},{key:'No', value:'No', text:'No'}]}
                                          onChange={this.memberFacingChange.bind(this)}
                                          value={this.state.selectedMemberFacing}
                                          clearable={true} />

                                <p className="module-header-1">Member Impacting <Popup content={HelpTips.MAIN_PAGE.MEMBER_IMPACTING} trigger={<Icon link name="info circle" />} /></p>
                                <Dropdown id="dropdown" selection fluid
                                          options={[{key:'Yes', value:'Yes', text:'Yes'},{key:'No', value:'No', text:'No'}]}
                                          onChange={this.memberImpactingChange}
                                          value={this.state.selectedMemberImpacting}
                                          clearable={true}/>

                                {/*/todo make this look better*/}
                                <Segment.Inline className="module-header-1" style={{border:"1px solid lightgrey", padding:"10px", margin:"20px 10px"}}>

                                        <Checkbox onChange={this.memberStatusChange}
                                                  checked={this.state.status}/>
                                    <span>&nbsp; Hide Canceled Changes</span>

                                </Segment.Inline>

                                <div style={{textAlign:"center"}}>
                                    <Button negative onClick={this.handleClear}>Clear All Filters</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

